<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import Layout from "../../../layouts/main.vue";
import Swal from "sweetalert2";
import PageHeader from "@/components/page-header";
import CustomField from "@/components/custom-field";
import ChatMessages from "@/components/chat-messages";
import appConfig from "../../../../app.config";
import { overlay } from "@/state/helpers";
import Popper from "vue3-popper";
import { DateTime } from "luxon";
import DropZone from "@/components/widgets/dropZone";
import animationData from "@/components/widgets/kbtmbyzy.json";
import {viewEntity, callUrl, getEntity, updateEntity, getUserData} from '@/helpers/api-apolo';
import {formatPrice, getTimezone, saSuccess, saError, sumBusinessProductPrices} from '@/helpers/global-functions';
import * as helpers from '@/helpers/helper-functions';
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import { Money3Component } from 'v-money3';

import "flatpickr/dist/flatpickr.css";

export default {
  page: {
    title: "Negócio",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Negócio",
      items: [
        {
          text: "CRM",
          href: "/",
        },
        {
          text: "Negócio",
          active: true,
        },
      ],
      moneyConfig: {
        masked: false,
        prefix: 'R$ ',
        suffix: '',
        thousands: '.',
        decimal: ',',
        precision: 2,
        disableNegative: true,
        disabled: false,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        modelModifiers: {
          number: false
        }
      },
      changed: {},
      reasons: [
        {value: 'not_responding', label: 'Não respondeu mais'},
        {value: 'will_postpone_the_process', label: 'Vai adiar o processo'},
        {value: 'canceled_the_process', label: 'Desistiu do processo'},
        {value: 'values_higher_than_expected', label: 'Valores acima do esperado'},
        {value: 'bought_with_the_competition', label: 'Fechou com a concorrência'},
        {value: 'dissatisfaction_with_the_service', label: 'Insatisfação com o serviço'},
      ],
      value: null,
      userIsMaster: false,
      configDateTime: {
        wrap: true,
        allowInput: true,
        enableTime: true,
        dateFormat: "Y-m-dTH:i:S",
        altInput: true,
        altFormat: "d/m/Y H:i"
      },
      defaultOptions: { animationData: animationData },
      deal: {person: {}, products: [], custom_field: []},
      stages: [],
      productsData: [],
      productsSelectedData: [],
      product: [],
      timeline: [],
      documents: [],
      tasks: [],
      users: [],
      additionalFields: [],
      userSelected: {},
      userToAssign: {},
      pipeline: {},
      types: [
        { id: 'call', name: 'Ligar' },
        { id: 'email', name: 'E-mail' },
        { id: 'meeting', name: 'Reunião' },
        { id: 'whatsapp', name: 'Whatsapp' },
        { id: 'task', name: 'Tarefa' },
        { id: 'deadline', name: 'Prazo' },
        { id: 'lunch', name: 'Almoço' }
      ],
      task: {
        id: '',
        code: '',
        user_id: '',
        type: '',
        description: '',
        estimated_closing_datetime: ''
      },
      comment: '',
      loadingComment: false,
      actualTab: 'timeline',
      currentChat: {},
      currentPerson: {}
    }
  },
  components: {
    Layout,
    CustomField,
    Popper,
    PageHeader,
    Multiselect,
    DropZone,
    flatPickr,
    money3: Money3Component,
    ChatMessages
  },
  computed: {
    showTheme() {
      return 'tooltip-' + this.$store.state.layout.mode
    },
    filteredUsers() {
      return this.users.filter(user => user.pipelineAttendant);
    }
  },
  methods: {
    ...overlay,
    ...helpers,
    async formatDeal(dataM) {
      let obj = dataM;
      obj.edit_description = false
      obj.edit_title = false
      const now = DateTime.now().setZone(getTimezone());
      if (!this.pipeline.id) {
        this.pipeline = await viewEntity(obj.pipeline_id, 'pipeline')
      }
      this.stages = JSON.parse(JSON.stringify(this.pipeline.phases));
      const deal_pipeline_phase_id = obj.pipeline_phases[0].pipeline_phase_id;
      let objPhases = JSON.parse(JSON.stringify(obj.pipeline_phases));
      if (obj.status === 'Fechado' || obj.status === 'Perdido') {
        for (let i = 0; i < this.stages.length; i++) {
          if (deal_pipeline_phase_id === this.stages[i].id) {
            break;
          }
          this.stages[i].previous = true;
        }
      }
      for (let i = 0; i < this.stages.length; i++) {
        let diffDealPhaseMinutes = 0;
        for (let j = 0; j < objPhases.length; j++) {
          if (objPhases[j].pipeline_phase_id === this.stages[i].id) {
            let exited_at = now;
            if (objPhases[j].exited_at && objPhases[j].exited_at !== '') {
              exited_at = DateTime.fromISO(objPhases[j].exited_at).setZone(getTimezone());
            }
            let begun_at = DateTime.fromISO(objPhases[j].begun_at).setZone(getTimezone());
            diffDealPhaseMinutes = diffDealPhaseMinutes + exited_at.diff(begun_at, 'minutes').minutes;
          }
        }
        const minutes = parseInt(diffDealPhaseMinutes)
        let hours = 0;
        if (minutes > 60) {
          hours = parseInt(minutes / 60);
        }
        let days = 0;
        if (hours > 24) {
          days = parseInt(hours / 24);
        }
        let months = 0;
        if (days > 0 && days > 30) {
          months = parseInt(days / 30);
        }
        let years = 0;
        if (months > 0 && months > 12) {
          years = parseInt(months / 12);
        }
        this.stages[i].date_diff_value = 0;
        this.stages[i].date_diff_type = 'minuto';
        this.stages[i].date_diff_flag = '';
        if (minutes > 0 && hours === 0) {
          this.stages[i].date_diff_value = minutes;
          if (minutes === 1) {
            this.stages[i].date_diff_type = 'minuto';
          } else {
            this.stages[i].date_diff_type = 'minutos';
          }
          this.stages[i].date_diff_flag = 'badge-soft-success';
        } else if (hours > 0 && days === 0) {
          this.stages[i].date_diff_value = hours;
          if (hours === 1) {
            this.stages[i].date_diff_type = 'hora';
          } else {
            this.stages[i].date_diff_type = 'horas';
          }
          this.stages[i].date_diff_flag = 'badge-soft-success';
        } else if (days > 0 && months === 0) {
          this.stages[i].date_diff_value = days;
          if (days === 1) {
            this.stages[i].date_diff_type = 'dia';
          } else {
            this.stages[i].date_diff_type = 'dias';
          }
          this.stages[i].date_diff_flag = 'badge-soft-success';
        } else if (months > 0 && years === 0) {
          if (months > 0 && months <= 3) {
            this.stages[i].date_diff_flag = 'badge-soft-success'
            this.stages[i].date_diff_value = months;
            if (months === 1) {
              this.stages[i].date_diff_type = 'mês';
            } else {
              this.stages[i].date_diff_type = 'meses';
            }
          } else if (months > 3 && months <= 6) {
            this.stages[i].date_diff_flag = 'badge-soft-warning'
            this.stages[i].date_diff_value = months;
            this.stages[i].date_diff_type = 'meses';
          } else if (months > 6) {
            this.stages[i].date_diff_flag = 'badge-soft-danger'
            this.stages[i].date_diff_value = months;
            this.stages[i].date_diff_type = 'meses';
          }
        } else if (years > 0) {
          this.stages[i].date_diff_flag = 'badge-soft-danger';
          this.stages[i].date_diff_value = years;
          if (years === 1) {
            this.stages[i].date_diff_type = 'ano';
          } else {
            this.stages[i].date_diff_type = 'anos';
          }
        }
        obj.updated = DateTime.fromISO(obj.updated_at).setZone(getTimezone()).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
        this.stages[i].selected = this.stages[i].id === deal_pipeline_phase_id;
        if (this.stages[i].selected) {
          this.stages[i].name_tooltip = '<b>' + this.stages[i].name + "</b><br><small>Está aqui há " + this.stages[i].date_diff_value + ' ' + this.stages[i].date_diff_type + '</small>'
        } else {
          this.stages[i].name_tooltip = '<b>' + this.stages[i].name + "</b><br><small>Esteve aqui por " + this.stages[i].date_diff_value + ' ' + this.stages[i].date_diff_type + '</small>'
        }
      }
      let value = 0;
      obj.features = [];
      for (let j = 0; j < obj.products.length; j++) {
        obj.products[j].loading = false
        obj.products[j].removing = false
        value = parseInt(value + obj.products[j].price * obj.products[j].quantity);
        if (obj.products[j].discount > 0) {
          value = parseInt(value - (value * obj.products[j].discount / 100));
        }
        obj.products[j].edit = false;
        obj.products[j].price_masked = (obj.products[j].price / 100);
        obj.products[j].price_formatted = formatPrice(obj.products[j].price)
        obj.features.push(obj.products[j].name)
      }
      obj.value = value;
      obj.value = value;
      if (!obj.actions) {
        obj.actions = [];
      }
      if (!obj.description) {
        obj.description = "";
      }
      obj.person_name = obj.person.name;
      const person = await this.getAvatar(obj.person.telephone);
      obj.person.avatar = ''
      if (person.profile && person.profile !== '') {
        obj.person.avatar = person.profile;
      }
      if (!this.users.length) {
        await this.getUsers();
      }
      this.userSelected = {}
      if (obj.attendants && obj.attendants[0]) {
        for (let i = 0; i < this.users.length; i++) {
          if (obj.attendants[0].user_id === this.users[i].id) {
            this.userSelected = this.users[i]
            break;
          }
        }
      }
      obj.value_formatted = formatPrice(sumBusinessProductPrices(obj.products));
      obj.product_label = 'Produtos';
      if (obj.products.length < 2) {
        obj.product_label = 'Produto';
      }
      document.title = obj.title + ' - ' + obj.value_formatted;
      this.title = obj.title + ' - <span style="color: green">' + obj.value_formatted + '</span>';
      if (obj.custom_field && obj.custom_field.length) {
        const oldCustomFields = JSON.parse(JSON.stringify(obj.custom_field));
        obj.custom_field = {};
        for (let i=0; i<oldCustomFields.length; i++) {
          obj.custom_field[oldCustomFields[i].field_id] = oldCustomFields[i].value;
        }
      } else {
        obj.custom_field = {};
      }
      this.deal = obj;
    },
    changeTab(val) {
      this.actualTab = val;
    },
    async selectedFile() {
      let files = document.querySelector(".dropzoneFile").files;
      if (files.length > 0) {
        this.changeShowOverlay({show: true});
        let url = '/business/' + this.$route.params.id + '/document'
        for (let i = 0; i < files.length; i++) {
          try {
            var formData = new FormData();
            formData.append("file", files[i]);
            await callUrl(formData, url, 'post', 'formData')
                .then(async () => {
                  this.getDealDocuments();
                  this.changeShowOverlay({show: false});
                  saSuccess("Upload concluído com Sucesso!")
                  // await this.getDeal(this.deal.id)
                });
          } catch (error) {
            this.changeShowOverlay({show: false});
            saError(error.message)
          }
        }
      }
    },
    async getAvatar(e164) {
      const data = await axios.get('https://api.fulfilling.io/verify-wa?api_key=5bc9d1c9f7473d50de34b4b7&api_secret=Gi33sGnbgNmz66Q/uE6RnyKUHrBzW05OMfJ8abUEeow=&e164=' + e164.replace('+', ''));
      if (data.data) {
        return data.data
      }
      return null;
    },
    formatAvatar(url) {
      if (/^http/.test(url)) {
        return url
      }
      return require('@/assets/images/avatars/profile.jpg')
    },
    async sendChangePhase(url, method) {
      this.changeShowOverlay({show: true});
      try {
        await callUrl({}, url, method)
          .then(async () => {
            this.getDealComments();
            this.changeShowOverlay({show: false});
            saSuccess("Negócio atualizado com Sucesso!")
            await this.getDeal(this.deal.id)
          });
      } catch (error) {
        this.changeShowOverlay({show: false});
        await this.getDeal(this.deal.id)
      }
    },
    async changePhase(phase_id) {
      if (phase_id !== this.deal.pipeline_phases[0].pipeline_phase_id) {
        let url = '/business/' + this.deal.id + '/pipeline-phase/' + phase_id;
        await this.sendChangePhase(url, 'post')
      }
    },
    async getDeal(id) {
      try {
        const dataM = await viewEntity(id, 'business');
        await this.formatDeal(dataM)
      } catch (error) {
        console.log(error.message);
      }
    },
    verifyWon(comment) {
      if (comment && comment.length > 0) {
        // verify if the comment is: Aplicado status "Fechado" ao Negócio
        if (comment.indexOf('Aplicado status "Fechado" ao Negócio') > -1) {
          return true;
        }
      }
      return false;
    },
    verifyIconColor(comment) {
      if (comment && comment.length > 0) {
        if (comment.indexOf('Aplicado status "Fechado" ao Negócio') > -1) {
          return 'border-won-icon-color';
        }
        if (comment.indexOf('Aplicado status "Perdido" ao Negócio') > -1) {
          return 'border-lost-icon-color';
        }
      }
      return 'border-system-icon-color';
    },
    verifyIcon(comment) {
      if (comment && comment.length > 0) {
        if (comment.indexOf('Aplicado status "Fechado" ao Negócio') > -1) {
          return 'la-rocket won-icon';
        }
        if (comment.indexOf('Aplicado status "Perdido" ao Negócio') > -1) {
          return 'la-skull-crossbones lost-icon';
        }
      }
      return 'la-user-secret system-icon';
    },
    async getProducts() {
      this.productsData = []
      this.productsSelectedData = []
      const data = await getEntity('product')
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let obj = data[i];
          obj.price_formatted = formatPrice(obj.price)
          obj.value = data[i].id;
          obj.label = data[i].name + ' - ' + obj.price_formatted;
          this.productsData.push(obj);
          let found = false;
          for (let j = 0; j < this.deal.products.length; j++) {
            let value = this.deal.products[j].price * this.deal.products[j].quantity;
            if (this.deal.products[j].discount > 0) {
              value = value - (value * this.deal.products[j].discount / 100);
            }
            value = parseInt(value);
            this.deal.products[j].price_formatted = formatPrice(value);
            if (this.deal.products[j].product === data[i].id) {
              found = true;
            }
          }
          if (!found) {
            this.productsSelectedData.push(obj);
          }
        }
      }
    },
    selectedProduct(id, product) {
      if (product) {
        this.createProductBusiness(product)
      }
    },
    changeProduct(product) {
      product.price = parseInt(product.price_masked * 100, 10);
      let value = product.price * product.quantity;
      if (product.discount > 0) {
        value = value - (value * product.discount / 100);
      }
      value = parseInt(value);
      product.price_formatted = formatPrice(value);
      this.deal.value = sumBusinessProductPrices(this.deal.products);
      this.deal.value_formatted = formatPrice(this.deal.value);
    },
    async updateArrayProducts(ind) {
      this.productsSelectedData = [];
      this.deal.products.splice(ind, 1)
      for (let i = 0; i < this.productsData.length; i++) {
        let obj = this.productsData[i];
        obj.price_formatted = formatPrice(obj.price)
        obj.value = this.productsData[i].id;
        obj.label = this.productsData[i].name + ' - ' + obj.price_formatted;
        let found = false;
        for (let j = 0; j < this.deal.products.length; j++) {
          let value = this.deal.products[j].price * this.deal.products[j].quantity;
          if (this.deal.products[j].discount > 0) {
            value = value - (value * this.deal.products[j].discount / 100);
          }
          value = parseInt(value);
          this.deal.products[j].price_formatted = formatPrice(value);
          if (this.deal.products[j].product === this.productsData[i].id) {
            found = true;
          }
        }
        if(!found) {
          this.productsSelectedData.push(obj);
        }
      }
      this.deal.value = sumBusinessProductPrices(this.deal.products);
      this.deal.value_formatted = formatPrice(this.deal.value);
      document.title = this.deal.title + ' - ' + this.deal.value_formatted;
      this.title = 'Negócio - ' + this.deal.title + ' - <span style="color: green">' + this.deal.value_formatted + '</span>';
    },
    async updateProduct(data) {
      data.loading = true;
      let obj = {price: data.price, discount: data.discount, quantity: data.quantity};
      try {
        await callUrl(obj, '/business/' + this.deal.id + '/product/' + data.product, 'put')
          .then(async () => {
            data.loading = false;
            document.title = this.deal.title + ' - ' + this.deal.value_formatted;
            this.title = 'Negócio - ' + this.deal.title + ' - <span style="color: green">' + this.deal.value_formatted + '</span>';
          });
      } catch (error) {
        this.changeShowOverlay({show: false});
        saError(error.message);
      }
    },
    async saveComment() {
      this.loadingComment = true
      if (this.comment === '') {
        return saError('Comentário não pode ser em branco!')
      }
      try {
        await callUrl({comment: this.comment}, '/business/' + this.deal.id + '/comment', 'post')
          .then(async () => {
            this.loadingComment = false
            this.comment = ''
            await this.getDealComments()
          });
      } catch (error) {
        this.loadingComment = false;
        saError(error.message);
      }
    },
    async removeProduct(data, ind) {
      data.removing = true;
      try {
        await callUrl({}, '/business/' + this.deal.id + '/product/' + data.product, 'delete')
          .then(async () => {
            data.removing = false;
            await this.updateArrayProducts(ind)
          });
      } catch (error) {
        this.changeShowOverlay({show: false});
        saError(error.message);
      }
    },
    async createProductBusiness(product) {
      let obj = {price: product.price, discount: product.discount, quantity: product.quantity};
      try {
        await callUrl(obj, '/business/' + this.deal.id + '/product/' + product.id, 'post')
          .then(async () => {
            this.product = []
            await this.getDeal(this.deal.id)
            await this.getProducts()
          });
      } catch (error) {
        saError(error.message);
      }
    },
    saveDescription() {
      let obj = {description: this.deal.description};
      callUrl(obj, '/business/' + this.deal.id, 'patch')
        .then(() => {
          this.deal.edit_description = false;
        })
    },
    saveTitle() {
      let obj = {title: this.deal.title};
      callUrl(obj, '/business/' + this.deal.id, 'patch')
        .then(() => {
          this.deal.edit_title = false;
          document.title = this.deal.title + ' - ' + this.deal.value_formatted;
          this.title = 'Negócio - ' + this.deal.title + ' - <span style="color: green">' + this.deal.value_formatted + '</span>';
        })
    },
    async getDealComments() {
      try {
        await callUrl({}, '/business/' + this.deal.id + '/comment', 'get')
          .then(async (data) => {
            this.timeline = []
            if (data?.length > 0) {
              for (let i = 0; i < data.length; i++) {
                let obj = data[i];
                if (obj.type === 'system') {
                  obj.avatar = require('@/assets/images/avatars/robot.png')
                  obj.user_name = 'Sistema'
                } else {
                  let user = {};
                  for (let j = 0; j < this.users.length; j++) {
                    if (obj.user_id === this.users[j].id) {
                      user = this.users[j];
                      break;
                    }
                  }
                  if (user.avatar) {
                    obj.avatar = user.avatar;
                  } else {
                    obj.avatar = require('@/assets/images/users/user-dummy-img.jpg');
                  }
                }
                obj.created = DateTime.fromISO(obj.created_at).setZone(getTimezone()).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
                this.timeline.push(obj)
              }
            }
          });
      } catch (error) {
        saError(error.message);
      }
    },
    getUsers() {
      return new Promise((resolve, reject) => {
        try {
          getEntity('user')
              .then(async (data) => {
                this.users = [];
                if (data?.length > 0) {
                  for (let i = 0; i < data.length; i++) {
                    let obj = data[i];
                    if (!obj.avatar) {
                      obj.avatar = require('@/assets/images/users/user-dummy-img.jpg');
                    }
                    let index = this.pipeline.attendants.findIndex((item) => item.user_id === obj.id);
                    obj.pipelineAttendant = index > -1;
                    this.users.push(obj)
                  }
                  resolve(true)
                }
              });
        } catch (error) {
          saError(error.message);
          reject(error);
        }
      });
    },
    async getDealDocuments() {
      try {
        await callUrl({}, '/business/' + this.deal.id + '/document', 'get')
          .then(async (data) => {
            this.documents = []
            if (data?.length > 0) {
              for (let i = 0; i < data.length; i++) {
                let obj = data[i];
                obj.created = DateTime.fromISO(obj.created_at).setZone(getTimezone()).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
                this.documents.push(obj)
              }
            }
          });
      } catch (error) {
        saError(error.message);
      }
    },
    async getAdditionalFields() {
      this.additionalFields = []
      const dataM = await callUrl({}, '/custom-field?entity=business&order=order:1', 'get')
      if (dataM && dataM.length) {
        this.additionalFields = dataM;
        for (let i=0; i < dataM.length; i++) {
          if (this.deal.custom_field.length > 0 && this.deal.custom_field[dataM[i].id] === undefined) {
            this.deal.custom_field[dataM[i].id] = (dataM[i].type === 'multi_enum' ? [] : '');
          }
        }
      }
    },
    formatMimeType(mime) {
      let body = '';
      if (mime && mime === 'application/pdf') {
        body = '<i class="mdi mdi-file-pdf-box" style="font-size: 35px;"></i>';
      } else if (mime && mime.search('word') >= 0) {
        body = '<i class="mdi mdi-file-word" style="font-size: 35px;"></i>';
      } else if (mime && mime.search('spreadsheet') >= 0) {
        body = '<i class="mdi mdi-file-excel" style="font-size: 35px;color:#008000;"></i>';
      } else if (mime && mime.search('csv') >= 0) {
        body = '<i class="mdi mdi-file-excel" style="font-size: 35px;color:#008000;"></i>';
      } else {
        body = '<i class="mdi mdi-file-image" style="font-size: 35px;"></i>'
      }
      return body;
    },
    async removeDocument(document, ind) {
      Swal.fire({
        title: "Você tem certeza?",
        html: "Será removido o documento <b>" + document.name + "</b><br>Essa é uma operação irreversível!!!",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "SIM, pode remover️️",
        cancelButtonText: "Cancelar",
        buttonsStyling: false,
        customClass: {
          actions: 'd-flex justify-content-center',
          confirmButton: 'btn btn-success flex-grow-1',
          cancelButton: 'btn btn-outline-dark flex-grow-1'
        }
      }).then(async (result) => {
        if (result.value) {
          try {
            this.changeShowOverlay({show: true});
            callUrl({}, '/business/' + this.deal.id + '/document/' + document.id, 'delete')
              .then(async () => {
                this.changeShowOverlay({show: false});
                this.documents.splice(ind, 1)
                Swal.fire("Sucesso!", "Removido com sucesso", "success");
                await this.getDealDocuments()
              }).catch((er) => {
                console.log(er);
              });
          } catch (error) {
            this.changeShowOverlay({show: false});
            saError(error.message);
            await this.getDealDocuments();
          }
        }
      });
    },
    cancelAttendant() {
      this.userToAssign = {};
    },
    selectAttendant(user) {
      this.userToAssign = user;
    },
    async setAttendant() {
      try {
        this.changeShowOverlay({show: true});
        await callUrl({}, '/business/' + this.deal.id + '/attendant/' + this.userToAssign.id, 'post')
          .then(async (data) => {
            this.userSelected = this.userToAssign;
            this.getDealComments();
            await this.formatDeal(data);
            saSuccess("Atendente atribuído com Sucesso!");
          });
      } catch (error) {
        saError(error.message);
      } finally {
        this.userToAssign = {};
        this.changeShowOverlay({show: false});
      }
    },
    async saveCustomFields() {
      let obj = JSON.parse(JSON.stringify(this.deal))
      delete obj.estimated_closing_date;
      const allowedCustomFields = this.additionalFields.map(field => field.id);
      obj.custom_field = Object.keys(obj.custom_field)
        .filter((k) => obj.custom_field[k].toString().length && allowedCustomFields.includes(k))
        .reduce((a, k) => ({ ...a, [k]: obj.custom_field[k] }), {});
      if (this.additionalFields.length) {
        for (let i=0; i<this.additionalFields.length; i++) {
          if (this.additionalFields[i].required && (!obj.custom_field[this.additionalFields[i].id] || !obj.custom_field[this.additionalFields[i].id].toString().length)) {
            saError(`O campo "${this.additionalFields[i].name}" é obrigatório`);
            return;
          }
        }
      }
      obj.custom_field = Object.keys(obj.custom_field).map(key => ({field_id: key, value: obj.custom_field[key]}));
      this.changeShowOverlay({show: true});
      updateEntity(obj, 'business')
        .then(() => {
          this.deal.edit_title = false;
          document.title = this.deal.title + ' - ' + this.deal.value_formatted;
          this.title = 'Negócio - ' + this.deal.title + ' - <span style="color: green">' + this.deal.value_formatted + '</span>';
          saSuccess("Dados salvos com Sucesso!");
        })
        .finally(() => this.changeShowOverlay({show: false}));
    },
    showTaskLabel (name) {
      const type = this.types.filter(t => t.id === name);
      if (type.length > 0) {
        return type[0]['name'];
      }
      return name;
    },
    openTaskModal (cleanData) {
      if (cleanData) {
        this.task.id                         = '';
        this.task.code                       = '';
        this.task.user_id                    = '';
        this.task.description                = '';
        this.task.estimated_closing_datetime = '';
      }
      document.getElementById('openTaskModal').click();
    },
    async getTasks() {
      this.tasks = [];
      const dataM = await callUrl({}, `/business/${this.deal.id}/task?order=status:0`, 'get');
      if (dataM && dataM.length) {
        this.tasks = dataM;
      }
    },
    async saveTask() {
      if (!this.task.user_id || !this.task.user_id.length) {
        saError('É obrigatório informar um responsável para criar uma tarefa');
        return;
      }
      if (!this.task.type || !this.task.type.length) {
        saError('É obrigatório informar o tipo para criar uma tarefa');
        return;
      }
      if (!this.task.estimated_closing_datetime || !this.task.estimated_closing_datetime.length) {
        saError('É obrigatório informar uma data de resolução para criar uma tarefa');
        return;
      }
      if (!this.task.description || !this.task.description.length) {
        saError('É obrigatório informar uma descrição para criar uma tarefa');
        return;
      }

      try {
        this.changeShowOverlay({show: true});
        if (this.task.id.length > 0) {
          const updatedTask = await callUrl(this.task, `/business/${this.deal.id}/task/${this.task.id}`, 'put')
          this.tasks = this.tasks.map(task => {
            if (task.id === this.task.id) {
              return updatedTask;
            }
            return task;
          });
        } else {
          const createdTask = await callUrl(this.task, `/business/${this.deal.id}/task`, 'post')
          this.tasks.push(createdTask);
        }
        this.changeShowOverlay({show: false});
        Swal.fire("Sucesso!", "Tarefa " + (this.task.id.length > 0 ? 'atualizada' : 'criada') + " com sucesso", "success");
        document.getElementById('closemodal').click();
      } catch (error) {
        this.changeShowOverlay({show: false});
        saError(error.message)
      }
    },
    async closeTask(taskId) {
      Swal.fire({
        title: "Você tem certeza que deseja fechar esta tarefa?",
        text: "Não será possível reverter esta ação!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Sim, fechar!",
        cancelButtonText: "Cancelar",
        buttonsStyling: false,
        customClass: {
          actions: 'd-flex justify-content-center',
          confirmButton: 'btn btn-success flex-grow-1',
          cancelButton: 'btn btn-outline-dark flex-grow-1'
        }
      }).then(async (result) => {
        if (result.value) {
          try {
            this.changeShowOverlay({show: true});
            await callUrl({}, `/business/${this.deal.id}/task/${taskId}/close`, 'post')
            this.changeShowOverlay({show: false});
            Swal.fire("Sucesso!", "Tarefa fechada com sucesso", "success");
            this.tasks = this.tasks.map(task => {
              if (task.id == taskId) {
                task.status = 'Fechado';
              }
              return task;
            });
          } catch (error) {
            this.changeShowOverlay({show: false});
            saError(error.message);
          }
        }
      });
    },
    prepareLost(deal) {
      this.changed = JSON.parse(JSON.stringify(deal));
      this.changed.reason_status = "Não respondeu mais"; //initialize with default value
    },
    cancelLost() {
      this.changed = {}
    },
    async setLost() {
      try {
        this.changeShowOverlay({show: true});
        await callUrl({reason_status: this.changed.reason_status, reason_observation: this.changed.reason_observation}, '/business/' + this.changed.id + '/lossed', 'post')
          .then(async () => {
            this.changeShowOverlay({show: false});
            saSuccess("Negócio perdido com sucesso 😭😭😭")
            await this.getDeal(this.changed.id)
            this.getDealComments();
            this.cancelLost()
          });
      } catch (error) {
        this.changeShowOverlay({show: false});
        saError(error.message);
        await this.getDeal(this.changed.id);
        this.cancelLost();
      }
    },
    async reopenDeal(deal_id) {
      try {
        this.changeShowOverlay({show: true});
        await callUrl({}, '/business/' + deal_id + '/opened', 'post')
          .then(async () => {
            await this.getDeal(deal_id)
            this.getDealComments();
            this.changeShowOverlay({show: false});
          });
      } catch (error) {
        this.changeShowOverlay({show: false});
        saError(error.message);
        await this.getDeal(deal_id);
      }
    },
    async setGain(deal) {
      Swal.fire({
        title: "Você tem certeza?",
        html: "Dar ganho para o negócio <b>" + deal.title + "</b>????",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "SIM 🎉🎉🎉",
        buttonsStyling: false,
        customClass: {
          actions: 'd-flex justify-content-center',
          confirmButton: 'btn btn-success flex-grow-1',
          cancelButton: 'btn btn-outline-dark flex-grow-1'
        }
      }).then(async (result) => {
        if (result.value) {
          try {
            this.changeShowOverlay({show: true});
            await callUrl({}, '/business/' + deal.id + '/done', 'post')
              .then(async () => {
                await this.getDealComments();
                this.changeShowOverlay({show: false});
                saSuccess("🎉🎉🎉 Parabéns pelo ganho 🎉🎉🎉!")
                await this.getDeal(deal.id)
              });
          } catch (error) {
            this.changeShowOverlay({show: false});
            saError(error.message);
            await this.getDeal(deal.id);
          }
        }
      });
    },
    async deleteTask(taskId) {
      Swal.fire({
        title: "Você tem certeza que deseja remover esta tarefa?",
        text: "Não será possível reverter esta ação!",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Sim, remover!",
        cancelButtonText: "Cancelar",
        buttonsStyling: false,
        customClass: {
          actions: 'd-flex justify-content-center',
          confirmButton: 'btn btn-success flex-grow-1',
          cancelButton: 'btn btn-outline-dark flex-grow-1'
        }
      }).then(async (result) => {
        if (result.value) {
          try {
            this.changeShowOverlay({show: true});
            await callUrl({}, `/business/${this.deal.id}/task/${taskId}`, 'delete')
            this.changeShowOverlay({show: false});
            Swal.fire("Sucesso!", "Tarefa removida com sucesso", "success");
            this.tasks = this.tasks.filter(task => task.id !== taskId);
          } catch (error) {
            this.changeShowOverlay({show: false});
            saError(error.message);
          }
        }
      });
    },
    editTask(taskId) {
      const currentTask = this.tasks.filter(task => task.id === taskId);
      if (currentTask.length > 0) {
        this.task.id                         = taskId;
        this.task.code                       = currentTask[0].code;
        this.task.user_id                    = currentTask[0].attendants[0].user_id;
        this.task.description                = currentTask[0].description;
        this.task.estimated_closing_datetime = currentTask[0].estimated_closing_datetime;
        this.openTaskModal(false);
      }
    },
    async getPersonData() {
      try {
        this.currentPerson = await callUrl({}, `/person/${this.deal.person.id}`, 'get');
      } catch (error) {
        console.error(`Falha ao buscar os dados da pessoa: ${error.message}`);
      }
    },
    async getCurrentChat() {
      try {
        const chats = await callUrl({}, `/chat?pipeline_id=${this.deal.pipeline_id}&business_id=${this.deal.id}`, 'get');
        if (chats && chats.length > 0) {
          this.currentChat = chats[0];
        }
      } catch (error) {
        console.error(`Falha ao buscar o chat do negócio: ${error.message}`);
      }
    },
    async createNewChat(messageObj) {
      try {
        this.changeShowOverlay({show: true});        
        const body = { business_id: this.deal.id, ...messageObj };
        const chat = await callUrl(body, `/chat`, 'post', '', false, false);
        this.currentChat = chat;
      } catch (error) {
        saError(error.message);
      } finally {
        this.changeShowOverlay({show: false});
      }
    },
    async revalidatePersonCommunication() {
      try {
        if (!this.currentPerson.id) {
          return;
        }
        this.changeShowOverlay({show: true});
        const person = await callUrl({}, `/person/${this.currentPerson.id}/validate-communications`, 'post');
        this.currentPerson = person;
      } catch (error) {
        saError(error.message);
      } finally {
        this.changeShowOverlay({show: false});
      }
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      this.changeShowOverlay({show: true});
      await this.getDeal(this.$route.params.id)
      await this.getDealComments()
      await this.getProducts()
      await this.getDealDocuments()
      this.getAdditionalFields();
      this.changeShowOverlay({show: false});
      this.getTasks();
      this.getPersonData();
      this.getCurrentChat();
    }
    const userData = getUserData();
    this.userIsMaster = (userData.profile === 'master' || userData.profile === 'coordenador');
  }
}
</script>

<style scoped>
.won-icon {
  color: #DAA520;
}
.lost-icon {
  color: #fb8b80;
}
.border-won-icon-color {
  border-color: #DAA520 !important;
}
.border-lost-icon-color {
  border-color: #fb8b80 !important;
}
.notAllowedClick {
  cursor: not-allowed;
  pointer-events: all;
}
.nav-link.active::before {
  border-left-color: rgba(13, 23, 51, 0.1);
}
.nav-link.active.won {
  background-color: #82b886;
}
.nav-link.active.lost {
  background-color: #fb8b80;
}
.nav-link.active.won::before {
  border-left-color: #82b886;
}
.nav-link.active.lost::before {
  border-left-color: #fb8b80;
}
.nav-link.previous::before {
  border-left-color: rgba(13, 23, 51, 0.1);
}
.nav-link.previous.won {
  background-color: #82b886;
}
.nav-link.previous.lost {
  background-color: #fb8b80;
}
.nav-link.previous.won::before {
  border-left-color: #82b886;
}
.nav-link.previous.lost::before {
  border-left-color: #fb8b80;
}
</style>

<template>
<Layout>
    <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center flex-wrap gap-2">
                    <div class="flex-grow-1">
                        <label for="title" class="form-label">Título do Negócio</label>&nbsp;
                        <a href="#" @click.prevent="deal.edit_title = true" v-show="!deal.edit_title"><i class="ri-pencil-line" /> Editar</a>
                        <a href="#" @click.prevent="saveTitle" v-show="deal.edit_title" class="text-success"><i class="ri-check-line" /> Salvar</a>
                        <p v-show="!deal.edit_title">{{deal.title}}</p>
                        <span v-show="deal.edit_title">
                            <input class="form-control" id="title" v-model="deal.title"/>
                        </span>
                        <p v-if="deal.status === 'Perdido'" class="text-danger mb-0"><strong>Motivo da perda: {{ deal.reason_status }}</strong></p>
                    </div>
                    <div class="flex-shrink-0" v-show="deal.status !== 'Perdido' && deal.status !== 'Fechado'">
                      <a href="#" @click.prevent="setGain(deal)" class="btn btn-success"><i class="ri-thumb-up-fill" /> Ganho</a>&nbsp;
                      <a href="#" data-bs-toggle="modal" data-bs-target="#setLostModal" @click.prevent="prepareLost(deal)" class="btn btn-danger"><i class="ri-thumb-down-fill" /> Perda</a>
                    </div>
                    <div class="flex-shrink-0" v-show="deal.status === 'Fechado'">
                      <b-button variant="success" pill class="waves-effect waves-light" style="margin-right: 10px; cursor: default !important;">Ganho</b-button>
                      <a href="#" @click.prevent="reopenDeal(deal.id)" class="btn btn-outline-info">Reabrir</a>&nbsp;
                    </div>
                    <div class="flex-shrink-0" v-show="deal.status === 'Perdido'">
                      <b-button variant="danger" pill class="waves-effect waves-light" style="margin-right: 10px; cursor: default !important;">Perdido</b-button>
                      <a href="#" @click.prevent="reopenDeal(deal.id)" class="btn btn-outline-info">Reabrir</a>&nbsp;
                    </div>
                  </div>
                  <div class="pt-3 border-top border-top-dashed mt-1">
                    <div class="col-12">
                      <div class="step-arrow-nav mb-4 progress_info">
                        <ul class="nav nav-pills custom-nav nav-justified" role="tablist">
                          <li class="nav-item deal-stage" v-for="(stage, key) in stages" :key="'stage-' + key" id="deal-stage-id">
                            <Popper hover :class="showTheme">
                              <button :class="['btn btn-link btn-md nav-link', stage.selected ? 'active' : '', stage.previous ? 'previous' : '', deal.status === 'Fechado' ? 'won' : '', deal.status === 'Perdido' ? 'lost' : '']" :style="[{cursor: stage.selected ? 'default' : 'pointer'}]" data-bs-trigger="tab" data-bs-toggle="pill" :href="'#animation-'+key" role="tab" @click.prevent="changePhase(stage.id)" :data-position=key aria-selected="true">
                                <div class="btn-content"><small>{{ stage.name }}</small><br>{{stage.date_diff_value}} {{stage.date_diff_type}}</div>
                              </button>
                              <template #content>
                                <div v-html="stage.name_tooltip" style="min-width: 150px !important;padding: 5px !important;"></div>
                              </template>
                            </Popper>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div><!--end card-->
            </div>
            <div class="col-xxl-3 col-xl-4">
                <!-- bloco de dados pessoais -->
                <div class="card">
                    <div class="card-body text-center" v-if="deal.person && deal.person.id">
                      <div class="position-relative d-inline-block">
                        <router-link :to="{ name: 'people-edit', params: { id: deal.person.id } }">
                          <img :src="formatAvatar(deal.person.avatar)" id="image" alt="" class="avatar-lg rounded-circle img-thumbnail">
                          <span class="contact-active position-absolute rounded-circle bg-success"><span
                              class="visually-hidden"></span></span>
                        </router-link>
                      </div>
                      <h5 class="mt-4 mb-1" id="name"><router-link :to="{ name: 'people-edit', params: { id: deal.person.id } }">{{ deal.person.name }}</router-link></h5>
                      <b-list-group>
                        <b-list-group-item class="list-group-item">
                          <div class="d-flex align-items-center">
                            <div class="flex-grow-1">
                              <div class="d-flex">
                                <div class="flex-shrink-0 avatar-xs">
                                  <a class="avatar-title bg-soft-success text-success rounded" :href="`tel:${deal.person.telephone}`"><i class="ri-phone-line"></i></a>
                                </div>
                                <div class="flex-shrink-0 ms-2">
                                  <h6 class="fs-10 mb-0" style="margin-top:7px !important;">
                                    <small style="vertical-align: sub !important;">{{deal.person.telephone}}</small>
                                  </h6>
                                </div>
                                <div v-if="!currentPerson?.communication_validations?.whatsapp" class="flex-grow-1 text-end align-self-center">
                                  <button class="btn btn-sm btn-light" @click="revalidatePersonCommunication">Validar Whatsapp</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-list-group-item>
                        <b-list-group-item class="list-group-item"><div class="d-flex align-items-center"><div class="flex-grow-1"><div class="d-flex"><div class="flex-shrink-0 avatar-xs"><a class="avatar-title bg-soft-danger text-danger rounded" :href="`mailto:${deal.person.email}`"><i class="ri-mail-line"></i></a></div><div class="flex-shrink-0 ms-2"><h6 class="fs-10 mb-0" style="margin-top:7px !important;"><small style="vertical-align: sub !important;">{{deal.person.email}}</small></h6></div></div></div></div></b-list-group-item>
                        <b-list-group-item class="list-group-item">
                          <div class="d-flex align-items-center">
                            <div class="flex-grow-1">
                              <div class="d-flex">
                                <div class="flex-shrink-0 avatar-xs">
                                  <router-link :to="{ name: 'chat-by-business', params: { pipelineId: deal.pipeline_id, businessId: deal.id } }" class="avatar-title bg-soft-warning text-warning rounded cursor-pointer"><i class="ri-question-answer-line"></i></router-link>
                                </div>
                                <div class="flex-shrink-0 ms-2">
                                  <h6 class="fs-10 mb-0" style="margin-top:7px !important;">
                                    <small style="vertical-align: sub !important;">Chat</small>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                </div><!--end card-->
                <!-- Bloco responsável -->
                <div class="card mb-3">
                    <div class="card-body">
                        <div class="d-flex mb-3">
                            <h6 class="card-title mb-0 flex-grow-1" style="display: flex;align-items: center;text-align: center;">Responsável</h6>
                            <div class="flex-shrink-0">
                                <button type="button" class="btn btn-soft-primary btn-sm" data-bs-toggle="modal" data-bs-target="#inviteMembersModal"><i class="ri-share-line me-1 align-bottom"></i> Atribuir</button>
                            </div>
                        </div>
                        <ul class="list-unstyled vstack gap-3 mb-0">
                            <li>
                                <div class="d-flex align-items-center" v-if="userSelected.id">
                                    <div class="flex-shrink-0">
                                        <img :src="userSelected.avatar" alt="" class="avatar-xs rounded-circle">
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <h6 class="mb-1">{{userSelected.name}}</h6>
                                        <p class="text-muted mb-0">{{userSelected.email}}</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center" v-else>
                                    <div class="flex-shrink-0">
<!--                                        <i class="las la-user-secret"></i>-->
                                        <img src="@/assets/images/avatars/robot.png" alt="" class="avatar-xs rounded-circle">
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <h6 class="mb-1">Sem Responsável</h6>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div><!--end card-->
                <!-- Bloco de campos do negocio-->
                <div v-if="additionalFields.length > 0" class="card mb-3">
                    <div class="card-body">
                        <div class="d-flex align-items-start">
                          <div class="flex-grow-1">
                            <h5 class="mb-4">Negócio</h5>
                          </div>
                        </div>
                        <div class="card-body row">
                          <div class="col-12">
                                    <label for="description" class="form-label">Descrição do Negócio</label>&nbsp;
                                    <a href="#" @click.prevent="deal.edit_description = true" v-show="!deal.edit_description"><i class="ri-pencil-line" /> Editar</a>
                                    <a href="#" @click.prevent="saveDescription" v-show="deal.edit_description" class="text-success"><i class="ri-check-line" /> Salvar</a>
                                    <p v-show="!deal.edit_description">{{deal.description}}</p>
                                    <span v-show="deal.edit_description">
                                        <textarea class="form-control" id="description" v-model="deal.description" rows="3"></textarea>
                                    </span>
                                </div>
                            <CustomField
                              v-for="(field, index) of additionalFields"
                              :key="'custom_field_' + index"
                              class="col-12 mb-3"
                              :field="field"
                              v-model="deal.custom_field[field.id]" />
                            <div class="col-12 mb-3 d-flex justify-content-end">
                              <a href="#" class="btn btn-success" @click.prevent="saveCustomFields">Salvar</a>
                            </div>
                        </div>
                    </div>
                </div><!--end card-->
            </div><!---end col-->
            <div class="col-xxl-9 col-xl-8">
                <div class="card" style="height: 500px; min-height: calc(100% - 1rem);overflow: hidden;">
                    <div class="card-header">
                        <div>
                            <ul class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                <li class="nav-item">
                                    <a :class="['nav-link',  actualTab === 'timeline' ? 'active' : '']" data-bs-toggle="tab" href="#home-1" role="tab" @click.prevent="changeTab('timeline')">
                                        Timeline
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a :class="['nav-link',  actualTab === 'attachments' ? 'active' : '']" data-bs-toggle="tab" href="#messages-1" role="tab" @click.prevent="changeTab('attachments')">
                                        Anexos
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a :class="['nav-link',  actualTab === 'tasks' ? 'active' : '']" data-bs-toggle="tab" href="#tasks-1" role="tab" @click.prevent="changeTab('tasks')">
                                        Tarefas
                                    </a>
                                </li>
                                <li class="nav-item" v-if="productsData.length > 0">
                                    <a :class="['nav-link',  actualTab === 'products' ? 'active' : '']" data-bs-toggle="tab" href="#products-1" role="tab" @click.prevent="changeTab('products')">
                                        Produtos
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a :class="['nav-link',  actualTab === 'chat' ? 'active' : '']" data-bs-toggle="tab" href="#chat-1" role="tab" @click.prevent="changeTab('chat')">
                                        Chat
                                    </a>
                                </li>
                            </ul><!--end nav-->
                        </div>
                    </div>
                    <div class="card-body h-75">
                        <div class="tab-content h-100">
                            <div :class="['tab-pane',  actualTab === 'timeline' ? 'active' : '']" style="height: -webkit-fill-available;" id="home-1" role="tabpanel">
                                <form class="mt-4 mb-4">
                                    <div class="row g-3">
                                        <div class="col-lg-12">
                                            <label for="exampleFormControlTextarea1" class="form-label">Incluir um Comentário</label>
                                            <textarea class="form-control border-light" id="exampleFormControlTextarea1" v-model="comment" @keydown.ctrl.enter.prevent="saveComment" rows="3" placeholder="Para enviar, utilize Ctr + Enter"></textarea>
                                        </div>
                                        <div class="col-12 text-end">
                                            <a href="#" @click.prevent="saveComment" class="btn btn-success" v-show="!loadingComment">Salvar</a>
                                            <a href="#" disabled class="btn btn-info" v-show="loadingComment">Aguarde...</a>
                                        </div>
                                    </div>
                                </form>
                                <div data-simplebar class="px-3 mx-n3 mb-2">
                                    <div class="d-flex mb-4" v-for="(data, key) in timeline" :key="'data-' + key">
                                        <div class="flex-shrink-0" v-if="data.type !== 'system'">
                                            <img :src="data.avatar" alt="" class="avatar-xs rounded-circle" />
                                        </div>
                                        <div :class="['flex-shrink-0', 'avatar-xs', 'rounded-circle', 'icon-rounded', verifyIconColor(data.comment)]" v-else>
                                            <i :class="['las', verifyIcon(data.comment), 'icon-avatar']"></i>
                                        </div>
                                        <div class="flex-grow-1 ms-3">
                                            <h5 class="fs-13">{{data.user_name}} <small class="text-muted">{{data.created}}</small></h5>
                                            <p class="text-muted">{{data.comment}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :class="['tab-pane',  actualTab === 'attachments' ? 'active' : '']" id="messages-1" role="tabpanel">
                                <div class="card-body">
                                  <DropZone @change="selectedFile" />
                                </div>
                                <div class="table-responsive table-card mt-1">
                                    <table class="table table-borderless align-middle mb-0">
                                        <thead class="table-primary text">
                                            <tr>
                                                <th scope="col">Arquivo</th>
                                                <th scope="col">Tipo</th>
                                                <th scope="col">Data Upload</th>
                                                <th scope="col">Ação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(document, key) in documents" :key="'document-' + key">
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <div class="avatar-sm">
                                                          <b-img v-if="document.mimetype.search('image') >=0" class="img-thumbnail" alt="200x200" width="200" :src="document.path" data-holder-rendered="true"></b-img>
                                                          <div v-else class="avatar-title bg-soft-primary text-primary rounded fs-20" v-html="formatMimeType(document.mimetype)"></div>
                                                        </div>
                                                        <div class="ms-3 flex-grow-1">
                                                            <h6 class="fs-15 mb-0"><a target="_blank" :href="document.path">{{document.name}}</a></h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{document.mimetype}}</td>
                                                <td>{{document.created}}</td>
                                                <td>
                                                    <a href="#" @click.prevent="removeDocument(document, key)"><i class="ri-delete-bin-5-line me-2 align-middle text-danger"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div :class="['tab-pane',  actualTab === 'tasks' ? 'active' : '']" id="tasks-1" role="tabpanel">
                              <div class="d-flex flex-row-reverse">
                                <span id="openTaskModal" data-bs-toggle="modal" data-bs-target="#taskModal"></span>
                                <button type="button"  class="btn btn-success btn-sm" @click.prevent="openTaskModal(true)">
                                  <i class="ri-add-line me-1 align-bottom"></i> Criar Tarefa
                                </button>
                              </div>
                              <table class="table table-striped table-borderless align-middle mb-0 mt-1">
                                <thead class="table-primary text-muted">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Criado em</th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Descrição</th>
                                        <th scope="col">Responsável</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Data Fechamento</th>
                                        <th scope="col">Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(task, key) in tasks" :key="'task-' + key">
                                    <td>{{task.code}}</td>
                                    <td>{{translateDateOnly(task.created_at)}} <small class="text-muted">{{ translateTimeOnly(task.created_at) }}</small></td>
                                    <td>{{showTaskLabel(task.type)}}</td>
                                    <td>{{task.description}}</td>
                                    <td>{{task.attendants[0].user_name}}</td>
                                    <td>{{task.status}}</td>
                                    <td v-if="task.estimated_closing_datetime && task.estimated_closing_datetime.length > 0">{{translateDateOnly(task.estimated_closing_datetime)}} <small class="text-muted">{{ translateTimeOnly(task.estimated_closing_datetime) }}</small></td>
                                    <td v-if="!task.estimated_closing_datetime || !task.estimated_closing_datetime.length > 0"></td>
                                    <td class="d-flex flex-column">
                                      <button v-if="task.status === 'Aberto'" type="button" class="btn btn-light btn-sm" @click.prevent="deleteTask(task.id)"><i class="ri-delete-bin-5-line me-2 text-danger"></i> Remover</button>
                                      <button v-if="task.status !== 'Aberto'" type="button" class="btn btn-light btn-sm disabled notAllowedClick"><i class="ri-delete-bin-5-line me-2 text-danger"></i> Remover</button>
                                      <button v-if="task.status === 'Aberto'" type="button" class="btn btn-light btn-sm mt-1" @click.prevent="closeTask(task.id)"><i class="mdi mdi-archive-check me-2 text-primary"></i> Fechar</button>
                                      <button v-if="task.status !== 'Aberto'" type="button" class="btn btn-light btn-sm mt-1 disabled notAllowedClick"><i class="mdi mdi-archive-check me-2 text-primary"></i> Fechar</button>
                                      <button v-if="task.status === 'Aberto'" type="button" class="btn btn-light btn-sm mt-1" @click.prevent="editTask(task.id)"><i class="mdi mdi-application-edit-outline me-2 text-primary"></i> Editar</button>
                                      <button v-if="task.status !== 'Aberto'" type="button" class="btn btn-light btn-sm mt-1 disabled notAllowedClick"><i class="mdi mdi-application-edit-outline me-2 text-primary"></i> Editar</button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div :class="['tab-pane',  actualTab === 'products' ? 'active' : '']" id="products-1" role="tabpanel">
                                <div class="search-box mb-3">
                                  <Multiselect class="form-control" v-model="product" id="product" placeholder="Adicionar Produto" :close-on-select="true" :searchable="true" :multiple="false" :create-option="false" mode="tags" track-by="label" label="label" @select="selectedProduct" :options="productsSelectedData" />
                                 </div>
                                <div class="mb-4 d-flex align-items-center">
                                    <div class="table-responsive" style="width: 100% !important;">
                                        <table class="table align-middle mb-0" id="deal-products">
                                            <thead class="table-primary">
                                                <tr>
                                                    <th scope="col">Nome</th>
                                                    <th scope="col">Preço</th>
                                                    <th scope="col">Quantidade</th>
                                                    <th scope="col">% Desconto</th>
                                                    <th scope="col">Valor</th>
                                                    <th scope="col">Ação</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(product, key) in deal.products" :key="'pDeal-' + key">
                                                    <td>{{product.name}}</td>
                                                    <td>
                                                      <money3
                                                        v-model.number="product.price_masked"
                                                        v-bind="moneyConfig"
                                                        @change="changeProduct(product)" />
                                                      <!-- <input type="number" min="1" v-model="product.price" @change="changeProduct(product)"> -->
                                                    </td>
                                                    <td><input type="number" min="1" v-model="product.quantity" @change="changeProduct(product)"></td>
                                                    <td><input type="number" min="0" max="99" v-model="product.discount" @change="changeProduct(product)"></td>
                                                    <td>{{product.price_formatted}}</td>
                                                    <td style="padding-right: 0;padding-left: 0;">
                                                      <b-button v-show="product.loading" variant="success" class="btn-icon waves-effect waves-light btn-load" disabled style="margin-right: 5px;"><span class="d-flex align-items-center"><span class="spinner-border flex-shrink-0" role="status"></span></span></b-button>
                                                      <b-button v-show="!product.loading" @click.prevent="updateProduct(product)" variant="success" class="btn-icon waves-effect waves-light" style="margin-right: 5px;"><i class="ri-check-line"></i></b-button>
                                                      <b-button v-show="product.removing" variant="danger" class="btn-icon waves-effect waves-light btn-load" disabled style="margin-right: 5px;"><span class="d-flex align-items-center"><span class="spinner-border flex-shrink-0" role="status"></span></span></b-button>
                                                      <b-button v-show="!product.removing" @click.prevent="removeProduct(product, key)" variant="danger" class="btn-icon waves-effect waves-light" style="margin-right: 5px;"><i class="ri-delete-bin-5-line"></i></b-button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot class="table-primary">
                                                <tr>
                                                    <td colspan="5">Total</td>
                                                    <td>{{deal.value_formatted}}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div :class="['tab-pane',  actualTab === 'chat' ? 'active' : '']" id="chat-1" role="tabpanel">
                              <chat-messages
                                v-if="actualTab === 'chat' && (currentChat?.id?.length > 0 || currentPerson?.communication_validations?.whatsapp === true)"
                                class="chat-inside-business"
                                :key="currentChat?.id ?? deal.id"
                                :chatSelected="currentChat"
                                :showDealsButton="false"
                                :shouldOverlay="false"
                                :setIntervalMessages="true"
                                @createNewChat="createNewChat"
                              />
                              <b-alert v-if="actualTab === 'chat' && (!currentChat.id && currentPerson?.communication_validations?.whatsapp === false)" show variant="warning">
                                Este usuário não possui a validação de que o número informado é uma conta de Whatsapp válida.
                              </b-alert>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal zoomIn" id="setLostModal" tabindex="-1" aria-labelledby="setLostModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="setLostModalLabel">Você tem certeza?</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <h6>Dar perda para o negócio <b>{{changed.title}}</b>????</h6>
                        <form>
                            <div class="mb-3">
                                <label for="recipient-name" class="col-form-label">Motivo:</label>
                                <select class="form-select mb-3" v-model="changed.reason_status" aria-label="Selecione o Motivo">
                                    <option v-for="(reason, key) in reasons" :key="'reason-' + key" :value="reason.label">{{reason.label}}</option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="message-text" class="col-form-label">Observação:</label>
                                <textarea class="form-control" v-model="changed.reason_observation" id="message-text"></textarea>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" style="color: #ffffff; background-color: #f46a6a !important;" data-bs-dismiss="modal" @click="cancelLost">Cancelar</button>
                        <button type="button" class="btn btn-primary" style="background-color: #34c38f !important;" @click.prevent="setLost" data-bs-dismiss="modal">SIM 😱</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" ref="taskModal" id="taskModal" tabindex="-1" aria-labelledby="taskModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content border-0">
              <div class="modal-header p-3 ps-4 bg-soft-info">
                <h5 class="modal-title">Tarefa</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body p-4">
                <div class="row g-3">
                  <div class="col-lg-4">
                    <label for="responsibleTask" class="form-label">Responsável *</label>
                    <select id="responsibleTask" class="form-control" v-model="task.user_id">
                      <option :value="''">Responsável pela tarefa</option>
                      <option v-for="(user, key) in users" :key="'user-' + key" :value="user.id">{{ user.name }}</option>
                    </select>
                  </div>
                  <div class="col-lg-4">
                    <label for="typeTask" class="form-label">Tipo *</label>
                    <select id="typeTask" class="form-control" v-model="task.type" :disabled="task.id.length > 0">
                      <option :value="''">Tipo de tarefa</option>
                      <option v-for="(type, key) in types" :key="'type-' + key" :value="type.id">{{ type.name }}</option>
                    </select>
                  </div>
                  <div class="col-lg-4">
                    <label for="estimatedClosingTask" class="form-label">Data de Resolução *</label>
                    <flat-pickr
                      v-model="task.estimated_closing_datetime"
                      :config="configDateTime"
                      :placeholder="'Data prevista para a tarefa ser concluída'"
                      :disabled="task.id.length > 0"
                      :id="'estimatedClosingTask'"
                      class="form-control flatpickr-input" />
                  </div>
                  <div class="col-lg-12">
                    <label for="descriptionTask" class="form-label">Descrição *</label>
                    <input v-model="task.description" type="text" id="descriptionTask" class="form-control" placeholder="Descrição da tarefa" />
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="hstack gap-2 justify-content-end">
                  <button type="button" class="btn btn-light" data-bs-dismiss="modal" id="closemodal">Fechar</button>
                  <button type="submit" class="btn btn-success" id="add-btn" @click.prevent="saveTask">Adicionar</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="productsModal" tabindex="-1" aria-labelledby="productsModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content border-0">
                    <div class="modal-header p-3 ps-4 bg-soft-info">
                        <h5 class="modal-title" id="productsModalLabel">Produtos</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-4">
                        <div class="search-box mb-3">
                            <Multiselect class="form-control" v-model="product" id="product" placeholder="Adicionar Produto" :close-on-select="true" :searchable="true" :multiple="false" :create-option="false" mode="tags" track-by="label" label="label" @select="selectedProduct" :options="productsSelectedData" />
                        </div>
                        <div class="mb-4 d-flex align-items-center">
                            <div class="table-responsive" style="width: 100% !important;">
                                <table class="table align-middle mb-0">
                                    <thead class="table-primary">
                                        <tr>
                                            <th scope="col">Nome</th>
                                            <th scope="col">Preço</th>
                                            <th scope="col">Quantidade</th>
                                            <th scope="col">% Desconto</th>
                                            <th scope="col">Valor</th>
                                            <th scope="col">Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(product, key) in deal.products" :key="'pDeal-' + key">
                                            <td>{{product.name}}</td>
                                            <td><input type="number" min="1" v-model="product.price" @change="changeProduct(product)"></td>
                                            <td><input type="number" min="1" v-model="product.quantity" @change="changeProduct(product)"></td>
                                            <td><input type="number" min="0" v-model="product.discount" @change="changeProduct(product)"></td>
                                            <td>{{product.price_formatted}}</td>
                                            <td>
                                              <b-button v-show="product.loading" variant="success" class="btn-icon waves-effect waves-light btn-load" disabled style="margin-right: 5px;"><span class="d-flex align-items-center"><span class="spinner-border flex-shrink-0" role="status"></span></span></b-button>
                                              <b-button v-show="!product.loading" @click.prevent="updateProduct(product)" variant="success" class="btn-icon waves-effect waves-light" style="margin-right: 5px;"><i class="ri-check-line"></i></b-button>
                                              <b-button v-show="product.removing" variant="danger" class="btn-icon waves-effect waves-light btn-load" disabled style="margin-right: 5px;"><span class="d-flex align-items-center"><span class="spinner-border flex-shrink-0" role="status"></span></span></b-button>
                                              <b-button v-show="!product.removing" @click.prevent="removeProduct(product, key)" variant="danger" class="btn-icon waves-effect waves-light" style="margin-right: 5px;"><i class="ri-delete-bin-5-line"></i></b-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="table-primary">
                                        <tr>
                                            <td colspan="5">Total</td>
                                            <td>{{deal.value_formatted}}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="inviteMembersModal" tabindex="-1" aria-labelledby="inviteMembersModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content border-0">
                    <div class="modal-header p-3 ps-4 bg-light">
                        <h5 class="modal-title" id="inviteMembersModalLabel">Responsáveis</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-4">
                        <div v-show="userSelected.id" class="mb-4 d-flex align-items-center">
                            <div class="me-2">
                                <h5 class="mb-0 fs-13">Responsável :</h5>
                            </div>
                            <div class="avatar-group justify-content-center">
                                <a href="javascript: void(0);" class="avatar-group-item d-flex align-items-center" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" :title="userSelected.email">
                                    <div class="avatar-xs flex-shrink-0 me-3">
                                        <img :src="userSelected.avatar" alt="" class="rounded-circle img-fluid" />
                                    </div>
                                    <div class="flex-grow-1">
                                        <h5 class="fs-13 mb-0 text-body d-block">{{userSelected.name}}</h5>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div v-show="!filteredUsers.length" class="modal-body p-4">
                          <p class="bg-warning p-2"><i class="mdi mdi-alert"></i> Não existem atendentes atribuídos ao pipeline para que possam ser escolhidos aqui.</p>
                          <p v-if="userIsMaster">Para realizar esta configuração vá até <router-link :to="{ name: 'pipeline-edit', params: { id: deal.pipeline_id } }" class="text-decoration-underline">"Pipelines" > "Edição de Pipeline" e abra a lista de atendentes associados</router-link>.</p>
                        </div>
                        <div v-show="filteredUsers.length" class="mx-n4 px-4" data-simplebar style="max-height: 225px;">
                            <div class="vstack gap-3">
                                <div class="d-flex align-items-center" v-for="(user, key) in filteredUsers" :key="'user-' + key">
                                    <div class="avatar-xs flex-shrink-0 me-3">
                                        <img :src="user.avatar" alt="" class="img-fluid rounded-circle">
                                    </div>
                                    <div class="flex-grow-1">
                                        <h5 class="fs-13 mb-0"><a href="javascript:void(0);" class="text-body d-block">{{user.name}}</a></h5>
                                    </div>
                                    <div class="flex-shrink-0">
                                        <button v-if="user.id === userSelected.id" type="button" disabled class="btn btn-outline-success btn-sm">Atribuído</button>
                                        <button v-if="user.id === userToAssign.id" type="button" class="btn btn-outline-warning btn-sm">Atribuição Pendente</button>
                                        <button v-if="user.id !== userSelected.id && user.id !== userToAssign.id" type="button" class="btn btn-light btn-sm" @click="selectAttendant(user)">Atribuir</button>
                                    </div>
                                </div>
                                <!-- end member item -->
                            </div>
                            <!-- end list -->
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light w-xs" data-bs-dismiss="modal" @click.prevent="cancelAttendant">Cancelar</button>
                        <button type="button" class="btn btn-success w-xs" data-bs-dismiss="modal" @click.prevent="setAttendant">Salvar</button>
                    </div>
                </div>
                <!-- end modal-content -->
            </div>
            <!-- modal-dialog -->
        </div>
        <!-- end modal -->
    </Layout>
</template>
<style scoped>
.icon-rounded {
  border: 2px solid #333;
  border-radius: 50%;
}
.icon-avatar {
  width: 100%;
  height: auto;
  font-size: 28px;
}
#chat-1 {
  background: url('~@/assets/images/chat-bg-pattern.png');
  transition: all .4s;
  position: relative;
  background-color: var(--bs-body-bg);
  height: 100%;
  padding: 10px 5px;
}
.chat-inside-business {
  height: 100%;
}
#deal-products tbody input {
  width: 150px;
  height: 31px;
  text-align: center;
}
#deal-products tbody tr td:nth-child(2) input {
  text-align: left;
}
@media screen and (max-width: 1800px) {
  #deal-products tbody input {
    width: 100px;
  }
}
@media screen and (max-width: 1400px) {
  #deal-products tbody input {
    width: 60px;
  }
  #deal-products tbody tr td:nth-child(2) input {
    width: 100px;
  }
}
</style>
