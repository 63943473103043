<template>
    <div :class="['chat-content', {'overlayChat': shouldOverlay}]">
        <div class="position-relative h-100 d-flex flex-column">
            <div class="p-3 user-chat-topbar">
                <div class="row align-items-center">
                    <div class="col-9">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 d-block d-lg-none me-3">
                                <a href="#" class="user-chat-remove fs-18 p-1" @click.prevent="cancelChat">
                                    <i class="ri-arrow-left-s-line align-bottom"></i>
                                </a>
                            </div>
                            <div class="flex-grow-1 overflow-hidden">
                                <div :class="['d-flex', 'align-items-center', {'cursor-pointer': showProfileButton}]" @click.prevent="openModalProfile">
                                    <div class="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                        <img :src="customerAvatar ? customerAvatar : require('@/assets/images/users/user-dummy-img.jpg')"
                                            class="rounded-circle avatar-xs" alt="" />
                                        <span class="user-status"></span>
                                    </div>
                                    <div class="flex-grow-1 overflow-hidden">
                                        <h5 class="text-truncate mb-0 fs-16">
                                            <span class="text-reset username">{{customerName}}</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <ul class="list-inline user-chat-nav text-end mb-0">
                            <li v-if="chatId.length > 0" class="list-inline-item m-0">
                                <div class="dropdown">
                                    <button class="btn btn-ghost-secondary btn-icon" type="button" @click.prevent="openSearch" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <search-icon class="icon-sm"></search-icon>
                                    </button>
                                    <div class="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg">
                                    <div class="p-2">
                                        <div class="search-box">
                                            <input ref="inputSearch" v-model="inputSearch" type="text" class="form-control bg-light border-light pe-5" placeholder="Pesquisar..." id="searchMessage" />
                                            <i class="ri-search-2-line search-icon"></i>
                                            <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" @click.prevent="cancelSearchMessages">
                                                <i class="ri-close-fill align-middle"></i>
                                            </button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </li>

                            <li class="list-inline-item d-inline-block m-0">
                                <button v-if="showDealsButton" type="button" title="Negócios" class="btn btn-ghost-secondary btn-icon" @click.prevent="openModalDeals">
                                    <i class="bx bx-money-withdraw align-bottom fs-4"></i>
                                </button>
                            </li>

                            <li class="list-inline-item d-inline-block m-0">
                                <button type="button" title="Marcar como lido" class="btn btn-ghost-secondary btn-icon" @click.prevent="markAsRead">
                                    <i class="bx bx-bookmark-plus align-bottom fs-4"></i>
                                </button>
                            </li>

                            <!-- <li class="list-inline-item m-0">
                                <div class="dropdown">
                                    <button class="btn btn-ghost-secondary btn-icon" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <more-vertical-icon class="icon-sm"></more-vertical-icon>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-end">
                                        <a class="dropdown-item" href="#">
                                            <i class="ri-inbox-archive-line align-bottom text-muted me-2"></i> Arquivar
                                        </a>
                                    </div>
                                </div>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="position-relative h-75 flex-grow-1 d-flex flex-column justify-content-end" id="users-chat">
                <div class="chat-conversation h-100 p-3 p-lg-4" id="chat-conversation" data-simplebar ref="scroll">
                    <ul :class="['list-unstyled', 'chat-conversation-list', { 'fade-ini': isLoadingInitial }, { 'fade-fim': !isLoadingInitial }]">
                        <li v-if="isLoadingMessage" class="chat-list p-2 d-flex justify-content-center align-items-center">
                            <i class="mdi mdi-loading mdi-spin fs-3 align-middle me-2"></i>
                        </li>
                        <li v-for="data of chatMessagesData" :key="data.id" :class="['chat-list', data.from === 'customer' ? 'left' : 'right']">
                            <div class="conversation-list">
                                <div class="chat-avatar">
                                    <img :src="data.avatar ? data.avatar : require('@/assets/images/users/user-dummy-img.jpg')" alt="" />
                                </div>
                                <div class="user-chat-content">
                                    <div class="row" :id="data.id">
                                        <div class="col-12">
                                            <div class="ctext-wrap">
                                                <div :class="['ctext-wrap-content', {'unReadMessageBg': data.pending_to_read > 0}]">
                                                    <span v-if="data.user_name !== ''"><small><b>{{data.user_name}}</b></small></span>
                                                    <p v-if="data.type !== 'media'" class="mb-0 ctext-content" v-html="processMessage(data)"></p>
                                                    <div v-if="data.type === 'media'" class="message-img mb-0">
                                                        <div class="message-img-list">
                                                            <a class="popup-img d-inline-block" :href="data.data" target="_blank">
                                                                <span v-html="processMessage(data)"></span>
                                                            </a>
                                                            <div class="message-img-link">
                                                                <ul class="list-inline mb-0">
                                                                    <li class="list-inline-item dropdown">
                                                                        <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            <i class="ri-more-fill"></i>
                                                                        </a>
                                                                        <div class="dropdown-menu">
                                                                            <a class="dropdown-item" href="#" @click.prevent="setReply(data)">
                                                                                <i class="ri-reply-line me-2 text-muted align-bottom"></i>Responder
                                                                            </a>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="data.type !== 'media'" class="dropdown align-self-start message-box-drop">
                                                    <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="ri-more-2-fill"></i>
                                                    </a>
                                                    <div class="dropdown-menu">
                                                        <a class="dropdown-item reply-message" href="#" @click.prevent="setReply(data)">
                                                            <i class="ri-reply-line me-2 text-muted align-bottom"></i>Responder
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="conversation-name">
                                                <small class="text-muted time">{{data.show_date }}</small>
                                                <span :class="[data.external_id && data.external_id !== '' ? 'text-success' : 'text', 'check-message-icon']">
                                                    <i v-if="data.isLoading" class="mdi mdi-spin mdi-loading me-2"></i>
                                                    <i v-if="!data.isLoading && data.external_id && data.external_id !== ''" class="ri-check-double-line align-bottom"></i>
                                                    <i v-if="!data.isLoading && (!data.external_id || data.external_id === '')" class="ri-check-line align-bottom"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="isLoadingMessage && actualPage <= 1" class="chat-conversation p-3 p-lg-4 d-flex justify-content-center align-items-center">
                    <i class="mdi mdi-loading mdi-spin fs-1 align-middle me-2"></i>
                </div>
            </div>

            <div class="chat-input-section p-3 p-lg-3">
                <form @submit.prevent="formSubmit">
                    <EmojiPicker :native="true" :theme="themeMode" @select="selectEmoji" v-if="showEmoji" />
                    <div class="row g-0 align-items-center">
                        <audio-recorder
                            v-show="audioRecord"
                            :upload-url="uploadUrl"
                            :filename="'AudioRecorder'"
                            :headers="headers"
                            :startRecording="audioRecord"
                            v-on:before-recording="beforeRecording"
                            v-on:after-recording="afterRecording"
                            v-on:clearRecording="audioRecord = !audioRecord"
                            class="audio-recorder"
                        />
                        <div class="col-auto">
                            <div class="chat-input-links me-2">
                                <div class="links-list-item">
                                    <button type="button" class="btn btn-link text-decoration-none emoji-btn" id="emoji-btn" @click.prevent="showEmoji = !showEmoji">
                                        <i v-if="!showEmoji" class="bx bx-smile align-middle"></i>
                                        <i v-else class="ri ri-close-circle-line align-middle"></i>
                                    </button>
                                    <button type="button" class="btn btn-link text-decoration-none upload-btn" id="upload-btn" data-bs-toggle="modal" data-bs-target=".uploadModal" @click.prevent="showUpload = !showUpload">
                                        <i v-if="!showUpload" class="ri ri-attachment-2 align-middle"></i>
                                        <i v-else class="ri ri-close-circle-line align-middle"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div :class="['col', audioRecord ? 'showingRecorder' : '']">
                            <div class="chat-input-feedback">Escreva a sua mensagem</div>
                            <input type="text" v-model="form.message" class="form-control chat-input bg-light border-light" @keydown.ctrl.enter.prevent="formSubmit" placeholder="Escreva a mensagem..." />
                        </div>
                        <div class="col-auto" v-show="!audioRecord">
                            <div class="chat-input-links ms-2">
                                <div class="links-list-item">
                                    <button type="submit" class="btn chat-send text-decoration-none" v-show="form.message !== '' && !audioRecord">
                                        <i class="ri-send-plane-2-fill align-bottom"></i>
                                    </button>
                                    <button type="button" class="btn chat-send text-decoration-none" @mouseenter="micHover = true" @mouseleave="micHover = false" v-show="form.message === '' && !audioRecord" @click.prevent="audioRecord = !audioRecord">
                                        <i :class="['bx', validateIconMic, 'align-bottom', 'fs-1']"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div :class="['replyCard', {'d-none': !showReply}, {'show' : showReply}]">
                <div class="card mb-0">
                    <div class="card-body py-3">
                        <div class="replymessage-block mb-0 d-flex align-items-start">
                            <div class="flex-grow-1">
                                <h5 class="conversation-name">{{reply.from_formatted}}</h5>
                                <p class="mb-0" v-html="reply.data"></p>
                            </div>
                            <div class="flex-shrink-0">
                                <button type="button" id="close_toggle" class="btn btn-sm btn-link mt-n2 me-n3 fs-18" @click.prevent="cancelReply">
                                    <i class="bx bx-x align-middle"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal v-model="showUpload" hide-footer class="v-modal-custom" hide-header-close centered>
            <div class="card-body">
                <DropZone
                    :key="'chat-upload'"
                    :dropzoneFileName="'dropzoneFileChat'"
                    @change="uploadFile" id="chat-upload"
                />
            </div>
        </b-modal>
    </div>
</template>


<script>
import moment from "moment";
import 'moment-timezone';
import {SearchIcon} from '@zhuowenli/vue-feather-icons'
import EmojiPicker from 'vue3-emoji-picker'
import '../../node_modules/vue3-emoji-picker/dist/style.css'
import AudioRecorder from '@/views/apps/recorder';
import DropZone from "@/components/widgets/dropZone";
import {callUrl, getUserData} from '@/helpers/api-apolo';
import {saError} from '@/helpers/global-functions';
import {generateId} from "@/helpers/helper-functions";
import {overlay} from "@/state/helpers";

export default {
    name: 'ChatMessages',
    components: {
        DropZone,
        AudioRecorder,
        EmojiPicker,
        SearchIcon
    },
    props: {
        setIntervalMessages: {
            type: Boolean,
            default: false
        },
        shouldOverlay: {
            type: Boolean,
            default: true
        },
        chatSelected: {
            type: Object,
            required: true
        },
        showDealsButton: {
            type: Boolean,
            default: false
        },
        showProfileButton: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            chatId: '',
            customerAvatar: '',
            customerName: '',
            chatMessagesData: [],
            isLoadingInitial: false,
            isLoadingMessage: false,
            isLoadingNewMessage: false,
            audioRecord: false,
            headers: {},
            audio: '',
            showEmoji: false,
            showUpload: false,
            micHover: false,
            showReply: false,
            loadingSending: false,
            form: {
                message: ""
            },
            reply: {
                external_external_id: '',
                from_formatted: '',
                data: ''
            },
            inputSearch: '',
            actualPage: 1,
            limitPage: 20,
            finishSearch: false,
            intervalInSecondsToFindMessages: 10,
            userData: {}
        }
    },
    computed: {
        uploadUrl() {
            return `/chat/${this.chatId}/document`;
        },
        validateIconMic() {
            return this.micHover ? 'bxs-microphone' : 'bx-microphone';
        }
    },
    watch: {
      async inputSearch() {
        this.actualPage = 1;
        this.finishSearch = false;
        await this.getChatMessages();
      },
      chatSelected: {
        handler(val) {
          this.changedChat(val);
        },
        deep: true
      }
    },
    methods: {
        ...overlay,
        changedChat(val) {
          this.chatId = val.id;
          this.customerAvatar = val.customer.avatar;
          this.customerName = val.customer.name;
          if (this.chatId !== val.id) {
              this.getChatMessages(true);
          } else {
              this.getNewChatMessages();
              this.bindScroll();
          }
        },
        getSimpleBar () {
            return document.getElementById('chat-conversation').querySelector(
                "#chat-conversation .simplebar-content-wrapper") ?
                document.getElementById('chat-conversation').querySelector(
                    "#chat-conversation .simplebar-content-wrapper") : '';
        },
        scrollToBottom() {
            setTimeout(() => {
                const simpleBar = this.getSimpleBar();
                if (simpleBar) {
                    const element = document.getElementById('chat-conversation').getElementsByClassName("chat-conversation-list");
                    const offsetHeight = element && element[0]
                        ? element[0].scrollHeight - window.innerHeight + 600
                        : 0;
                    if (offsetHeight) {
                        simpleBar.scrollTo({
                            top: offsetHeight,
                            behavior: "instant"
                        });
                    }
                }
            }, 0);
        },
        scrollToBottomWithNewmessages() {
            const simpleBar = this.getSimpleBar();
            if (simpleBar) {
                const element = document.getElementById('chat-conversation').getElementsByClassName("simplebar-content-wrapper");
                if (element[0].clientHeight) {
                    if (Math.ceil(element[0].scrollTop + element[0].clientHeight) > (element[0].scrollHeight / 2)) {
                        this.scrollToBottom();
                    }
                }
            }
        },
        bindScroll () {
            setTimeout(() => {
                const simpleBar = this.getSimpleBar();
                if (simpleBar) {
                    simpleBar.addEventListener("scroll", event => this.onScrollMessages(event));
                }
            }, 100);
        },
        formatMessages(unformatedMessages) {
            return new Promise((resolve, reject) => {
              try {
                const messages = [];
                for (let i = 0; i < unformatedMessages.length; i++) {
                  let obj = unformatedMessages[i];
                  if (obj.avatar === "/d/img/avatar-2-64.png" || obj.avatar === "//static.ffid.io/admin/img/avatar-2-64.png") {
                    obj.avatar = this.customerAvatar ? this.customerAvatar : require('@/assets/images/users/user-dummy-img.jpg');
                  }
                  if (obj.from === 'robo') {
                    obj.avatar = require('@/assets/images/avatars/robot.png');
                  }
                  
                  obj.show_date = this.formatShowDate(obj.created_at);

                  messages.unshift(obj)
                }
                resolve(messages);
              }
              catch (e) {
                console.log(e);
                reject(e);
              }
            });
        },
        formatShowDate(date) {
            const createdDate = moment(date).tz('America/Sao_Paulo');
            const today = moment().tz('America/Sao_Paulo').startOf('day');
            
            if (createdDate.isSame(today, 'day')) {
                return createdDate.format('HH:mm');
            }
            
            return createdDate.format('DD/MM/YYYY HH:mm');
        },
        async getNewChatMessages() {
            if (this.inputSearch && this.inputSearch.length >= 3) {
                return;
            }
            try {
                if (this.isLoadingNewMessage) {
                    return;
                }
                this.isLoadingNewMessage = true;

                const params = [`page=1`, `limit=${this.limitPage}`, 'order=created_at:0'];
                const response = await callUrl({}, `/chat/${this.chatId}/message?${params.join('&')}`, 'get');
                if (response && response.length) {
                    const messages = [];
                    let changed = false;
                    for (let i = 0; i < response.length; i++) {
                        const existedMessage = this.chatMessagesData.filter(message => message.id === response[i].id);
                        if (existedMessage.length === 0) {
                            messages.push(response[i]);
                            changed = true;
                        }
                    }
                    if (messages.length) {
                        let resolve = await this.formatMessages(messages);
                        this.chatMessagesData = this.chatMessagesData.concat(resolve);
                        if (changed) {
                            // this.scrollToBottomWithNewmessages();
                            this.scrollToBottom();
                        }
                    }
                }
            } catch (error) {
                console.log(error.message);
            } finally {
                this.isLoadingNewMessage = false;
            }
        },
        async getChatMessages(firstTime = false) {
            try {
                if (this.isLoadingMessage || this.finishSearch) {
                    return;
                }
                this.isLoadingMessage = true;
                
                if (firstTime) {
                  this.isLoadingInitial = true;
                }
                if (this.actualPage <= 1) {
                    this.chatMessagesData = [];
                }

                const params = [`page=${this.actualPage}`, `limit=${this.limitPage}`, 'order=created_at:0'];
                if (this.inputSearch && this.inputSearch.length >= 3) {
                    params.push(`query=${this.inputSearch}`);
                }
                const response = await callUrl({}, `/chat/${this.chatId}/message?${params.join('&')}`, 'get');
                if (response && response.length) {
                    let resolve = await this.formatMessages(response);
                    this.chatMessagesData = resolve.concat(this.chatMessagesData);
                } else {
                    this.finishSearch = true;
                }
            } catch (error) {
                saError(error.message);
            } finally {
                if (this.actualPage <= 1) {
                    this.scrollToBottom();
                    this.bindScroll();
                }
                this.isLoadingMessage = false;
                this.isLoadingInitial = false;
            }
        },
        openSearch() {
            this.$refs.inputSearch.focus()
        },
        async onScrollMessages(e) {
          const {scrollTop} = e.target
          if (scrollTop < 200 && !this.isLoadingMessage) {
            this.actualPage++;
            await this.getChatMessages();
          }
        },
        cancelChat () {
            this.$emit('close');
        },
        cancelSearchMessages () {
            this.inputSearch = '';
        },
        openModalDeals () {
            this.$emit('openModalDeals');
        },
        openModalProfile () {
            this.$emit('openModalProfile');
        },
        themeMode() {
            return this.$store.state.layout.mode;
        },
        selectEmoji (emoji) {
            this.form.message += emoji.i;
            this.showEmoji = false;
        },
        formatEmoji (message) {
            return (message || '').replace(
                /:[^:\s]+:/gi,
                (match) => {
                    return '<i class="eo-16 eo-' + match.substr(1, (match.length - 2)) + '"></i>'
                }
            )
        },
        formatItalic (message) {
            return (message || '').replace(
                /\s_[^_]+_/gi,
                (match) => {
                    return '<i style="font-size:inherit;color:inherit"> ' + match.substr(2, (match.length - 3)) + '</i>'
                }
            )
        },
        formatBold (message) {
            return (message || '').replace(
                /\*[^*]+\*/gi,
                (match) => {
                    return '<b>' + match.substr(1, (match.length - 2)) + '</b>'
                }
            )
        },
        formatStrikethrough (message) {
            return (message || '').replace(
                /~[^~]+~/gi,
                (match) => {
                    return '<del>' + match.substr(1, (match.length - 2)) + '</del>'
                }
            )
        },
        formatMonospace (message) {
            return (message || '').replace(
                /`{3}[^`{3}]+`{3}/gi,
                (match) => {
                    return '<kbd>' + match.substr(3, (match.length - 6)) + '</kbd>'
                }
            )
        },
        formatLinksByAnchors (message) {
            return (message || '').replace(
                /(((https?:\/\/)|(www\.))(\S+))/gi,
                (match, url) => {
                    let hyperlink = url
                    if (!hyperlink.match('^https?://')) {
                        hyperlink = 'https://' + hyperlink
                    }
                    return '<a href="' + hyperlink + '" target="_blank">' + url + '</a>'
                }
            )
        },
        processMessage_message (val) {
            let message = ''
            if (val.author && val.author !== '') {
                message = '<small style="color:grey">' + val.author + '</small><br>'
            }
            message += val.data
            message = this.formatEmoji(message)
            message = this.formatItalic(message)
            message = this.formatBold(message)
            message = this.formatStrikethrough(message)
            message = this.formatMonospace(message)
            message = this.formatLinksByAnchors(message)
            if (val.message_reply_id && val.message_reply_id !== '') {
                let messageReturn = '<span class="replyContentSpan">'
                messageReturn += '<span><b>' + val.message_reply_author + '</b></span><br>'
                let n = val.message_reply_content.search('https://cdn.fulfilling.io/template/media')
                if (n >= 0) {
                    let media = '<span>' + this.processMessage_media(val, '300px', true) + '</span>'
                    messageReturn += media
                } else {
                    messageReturn += '<span>' + this.formatItalic(val.message_reply_content) + '</span>'
                }
                messageReturn += '<br></span>'
                message = messageReturn + message
            }
            return message
        },
        processMessage_button (val) {
            let message = ''
            if (val.author && val.author !== '') {
                message = '<small style="color:grey">' + val.author + '</small><br>'
            }
            message += val.data
            message = this.formatEmoji(message)
            message = this.formatItalic(message)
            message = this.formatBold(message)
            message = this.formatStrikethrough(message)
            message = this.formatMonospace(message)
            message = this.formatLinksByAnchors(message)
            if (!val.active) {
                message += '<br><small style="color:grey">' + this.translate.Mensagem_expirada + '</small>'
            }
            if (val.buttons && val.buttons.length > 0) {
                let btn = '<br>'
                for (let i = 0; i < val.buttons.length; i++) {
                    btn += '<span style="border: none !important;color: white !important;padding: 5px !important;text-align: center !important;text-decoration: none !important;display: inline-block !important;font-size: 10px !important;margin: 4px 2px !important;background-color: #555555 !important;">' + val.buttons[i] + '</span>'
                }
                message += btn
            }
            return message
        },
        processMessage_document (val) {
            let message = ''
            if (val.author && val.author !== '') {
                message = '<small style="color:grey">' + val.author + '</small><br>'
            }
            const path = val.data;
            if (/\.ics$/.test(path)) {
                message += '<a href="' + path + '" target="_blank" title="Calendário"><i class="icon s-8 icon-calendar-check"></i></a>'
                return message
            }
            const arraySplit = path.split('/');
            message += '<a href="' + path + '" target="_blank"><i class="icon-file-document s-4"></i> ' + arraySplit[arraySplit.length - 1] + '</a>'
            return message
        },
        processMessage_media (val, size = '300px', reply = false) {
            let path = val.data;
            if (reply) {
                path = val.message_reply_content
            }
            let message = ''
            if (val.author && val.author !== '') {
                message = '<small style="color:grey">' + val.author + '</small><br>'
            }
            if (path.length === 0) {
                return false
            }
            const regex = new RegExp('.*\\.(.*)', 'g');
            const matches = regex.exec(path);
            if (matches[1] === 'jpg' || matches[1] === 'jpeg' || matches[1].toLowerCase() === 'gif' || matches[1].toLowerCase() === 'png') {
                message += '<image class="media_embed" style="max-width: ' + size + ';" src="' + path + '" />'
                return message
            }
            if (matches[1] === 'mp3' || matches[1] === 'ogg') {
                message += '<audio style="height:54px !important;max-width: ' + size + ';" controls="controls"><source src="' + path + '" type="audio/' + matches[1] + '" />Seu navegador não suporta HTML5</audio>'
                return message
            }
            if (matches[1] === 'm4a') {
                message += '<audio style="height:54px !important;max-width: ' + size + ';" controls="controls"><source src="' + path + '" type="audio/mp4" />Seu navegador não suporta HTML5</audio>'
                return message
            }
            if (matches[1] === 'mp4' || matches[1].toLowerCase() === 'mov') {
                let height = 'auto'
                if (size !== '300px') {
                    height = size
                }
                message += '<video preload="none" style="max-width:100%; height:' + height + ';" controls="controls"><source src="' + path + '"  type="video/mp4">Seu navegador não suporta HTML5</video>'
                return message
            }
            if (matches[1] === 'ogv') {
                let height = 'auto'
                if (size !== '300px') {
                    height = size
                }
                message += '<video preload="none" style="max-width:100%; height:' + height + ';" controls="controls"><source src="' + path + '"  type="video/ogg">Seu navegador não suporta HTML5</video>'
                return message
            }
            const arraySplit = path.split('/');
            message += '<a href="' + path + '" target="_blank"><i class="icon-folder-download s-4"></i> ' + arraySplit[arraySplit.length - 1] + '</a>'
            return message
        },
        processMessage_contact (path) {
            let message = ''
            if (path.author && path.author !== '') {
                message = '<small style="color:grey">' + path.author + '</small><br>'
            }
            message += '<i class="icon-account s-4"></i> Contato<br>' + path.data
            return message
        },
        processMessage (message) {
            let type = message.type
            if (type === 'photo' || type === 'audio' || type === 'video' || type === 'document') {
                type = 'media';
            }
            if (type === 'media') {
                const imageByFFID = message.data.search('https://cdn.fulfilling.io/template/media');
                const imageByApolo = message.data.search('https://cdn.apolo.vip/');
                if (imageByFFID < 0 && imageByApolo < 0) {
                    type = 'message';
                }
            }
            if (this['processMessage_' + type] && typeof this['processMessage_' + type] === 'function') {
                return this['processMessage_' + type](message)
            }
            return message.data
        },
        setReply (data) {
            this.reply = data
            this.showReply = true
        },
        cancelSubmit () {
            this.markkMessagesRead();
            this.form = {
                message: ''
            };
            this.cancelReply();
        },
        formatFakeAuthor () {
            if (this.reply.from === 'customer') {
              return this.customerName;
            }
            if (this.reply.from === 'robo') {
              return 'Robô';
            }
            return this.userData.name;
        },
        createFakeMessage (message, type) {
            let obj = {
                avatar: this.userData.avatar && this.userData.avatar !== '' ? this.userData.avatar : null,
                type: type,
                data: message,
                show_date: moment().format('HH:mm'),
                created_at: moment(),
                external_id: null,
                id: generateId(),
                isLoading: true
            };
            if (this.reply.external_external_id && this.reply.external_external_id !== '' && this.showReply) {
                obj.external_id = this.reply.external_external_id;
                obj.message_reply_author = this.formatFakeAuthor();
                obj.message_reply_content = this.reply.data;
                obj.message_reply_id = this.reply.external_external_id;
            }
            this.chatMessagesData.push(obj);
            return obj;
        },
        async formSubmit () {
            if (this.loadingSending) {
                return;
            }

            try {
                this.loadingSending = true;
                const body = { message: this.form.message }
                if (this.reply.external_external_id && this.reply.external_external_id !== '' && this.showReply) {
                    body.external_id = this.reply.external_external_id;
                }
                if (this.chatId.length === 0) {
                    this.$emit('createNewChat', body);
                    return;
                }
                const obj = this.createFakeMessage(this.form.message, 'message');
                this.form.message = '';
                this.showReply = false;
                this.scrollToBottom()
                const newMessage = await callUrl(body, `/chat/${this.chatId}/message`, 'post');
                this.chatMessagesData = this.chatMessagesData.map(message => {
                    if (message.id === obj.id) {
                        message = newMessage;
                    }
                    message.show_date = this.formatShowDate(message.created_at);
                    return message;
                });
                this.cancelSubmit();
            } catch (error) {
                saError(error.message);
            } finally {
                this.loadingSending = false;
            }
        },
        beforeRecording () {
            this.audio = ''
        },
        async sendMessageMedia (path) {
            const obj = this.createFakeMessage(path, 'media');
            this.changeShowOverlay({show: false});

            const newMessage = await callUrl({message: path, type: 'media'}, `/chat/${this.chatId}/message`, 'post');
            this.chatMessagesData = this.chatMessagesData.map(message => {
                if (message.id === obj.id) {
                    message = newMessage;
                }
                return message;
            });
            this.cancelSubmit();
        },
        async afterRecording(val) {
            if (this.audioRecord) {
                this.changeShowOverlay({show: true});
                const formData = new FormData();
                formData.append('audio', val.blob, 'AudioRecorder.mp3');
                const data = await callUrl(formData, this.uploadUrl, 'post', `multipart/form-data; boundary=${formData._boundary}`);
                this.audioRecord = false;
                this.sendMessageMedia(data.path);
            }
        },
        cancelReply() {
            this.reply = {
                from_formatted: '',
                data: ''
            };
            this.showReply = false
        },
        async uploadFile() {
            let files = document.getElementById("dropzoneFileChat").files;
            if (files.length > 0) {
                this.changeShowOverlay({show: true});
                for (let i = 0; i < files.length; i++) {
                    try {
                        this.changeShowOverlay({show: true});
                        const formData = new FormData();
                        formData.append("file", files[i]);
                        const data = await callUrl(formData, this.uploadUrl, 'post', 'formData');
                        this.showUpload = false;
                        this.sendMessageMedia(data.path);
                    } catch (error) {
                        this.changeShowOverlay({show: false});
                        saError(error.message);
                    }
                }
            }
        },
        async intervalChatsMessages() {
            this.interval_chat_messages = setInterval(async () => {
                await this.getNewChatMessages()
            }, this.intervalInSecondsToFindMessages * 1000)
        },
        markkMessagesRead() {
            this.chatMessagesData = this.chatMessagesData.map(message => {
                message.pending_to_read = 0;
                return message;
            });
        },
        async markAsRead() {
          try {
            this.changeShowOverlay({show: true});
            await callUrl({}, `/chat/${this.chatId}/read`, 'post');
            // mark all messages in chatMessagesData as read with pending_to_read = 0
            this.markkMessagesRead();
            this.$emit('markAsRead', this.chatId)
          } catch (error) {
            saError(error.message);
          } finally {
            this.changeShowOverlay({show: false});
          }
        }
    },
    beforeUnmount () {
      clearInterval(this.interval_chat_messages)
    },
    async mounted () {
        this.userData = getUserData();
        this.actualPage = 1;
        if(this.chatSelected && this.chatSelected.id) {
            this.changedChat(this.chatSelected);
            if (this.setIntervalMessages) {
                await this.intervalChatsMessages();
            }
        }
    }
}
</script>

<style scoped>
.v3-emoji-picker {
  position: absolute !important;
  bottom: 70px !important;
}
.audio-recorder {
    right: 10px;
}
.showingRecorder {
    max-width: calc(100% - 280px);
}
.unReadMessageBg {
    background-color: #FFEC73FF !important;
}
.fade-fim {
  opacity: 1;
  transition: opacity .25s linear;
  -moz-transition: opacity .25s linear;
  -webkit-transition: opacity .25s linear;
}

.fade {
  opacity: 1;
  animation-name: fadeTime;
  animation-duration: 1.5s;
}

@keyframes fadeTime {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-ini {
  opacity: 0;
}
</style>
