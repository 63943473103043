<template>
  <div class="ar">
    <div class="ar-content">
     <div class="ar-recorder_duration2">
       <i class="icon-brightness-1 icon-recording animate-flicker"></i>
       {{recordedTime}}
     </div>
      <div class="ar-recorder">
        <button type="button" id="btnCancel" class="rounded-circle avatar-xs icon-button ar-icon ar-icon__lg" @click.prevent="removeRecord">
          <span class="avatar-title bg-transparent text-reset">
            <i id="btnIconCancel" class="ri-close-line"></i>
          </span>
        </button>
        <button type="button" id="btnDone" class="rounded-circle avatar-xs icon-button ar-icon ar-icon__sm ar-recorder__stop" name="stop" @click.prevent="stopRecorder">
          <span class="avatar-title bg-transparent text-reset">
            <i id="btnIconDone" class="ri-check-line"></i>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Mp3Encoder } from 'lamejs'

export default {
  mixins: [{
    props: {
      filename: { type: String, default: 'record' },
      headers: { type: Object, default: () => ({}) },
      uploadUrl: { type: String }
    }
  }],
  props: {
    startRecording: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isUploading: false,
      recorder: {},
      recordList: [],
      selected: {},
      uploadStatus: null,
      bufferSize: 1024,
      isPause: false,
      isRecording: false,
      duration: 0,
      duration2: 0,
      volume: 0,
      bitRate: 128,
      sampleRate: 48000,
      dataBuffer: [],
      encoder: {}
    }
  },
  beforeUnmount () {
    this.stopRecorder()
  },
  methods: {
    toggleRecorder () {
      if (!this.isRecording || (this.isRecording && this.isPause)) {
        this.start()
      } else {
        this.pause()
      }
    },
    stopRecorder () {
      if (!this.isRecording) {
        return
      }
      this.stop()
    },
    removeRecord () {
      this.recordList = []
      // this.$set(this.selected, 'url', null)
      this.clearRecording()
      this.$emit('clearRecording')
    },
    convertTimeMMSS (seconds) {
      if (seconds) {
        return new Date(seconds * 1000).toISOString().substr(14, 5)
      }
      return '00:00'
    },
    setRecorder () {
      this.bufferSize = 1024
      this.isPause = false
      this.isRecording = false
      this.duration = 0
      this.volume = 0
    },
    start () {
      const constraints = {
        video: false,
        audio: {
          channelCount: 1,
          echoCancellation: false
        }
      }
      this.$emit('before-recording', 'vai gravar')
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(this._micCaptured.bind(this))
        .catch(this._micError.bind(this))
      this.isPause = false
      this.isRecording = true
      this.encoder = new Mp3Encoder(1, this.sampleRate, this.bitRate)
    },
    stop () {
      this.stream.getTracks().forEach((track) => track.stop())
      this.input.disconnect()
      this.processor.disconnect()
      this.context.close()
      const record = this.finish()
      record.duration = this.convertTimeMMSS(this.duration)
      this.duration = 0
      this.isPause = false
      this.isRecording = false
      this.$emit('after-recording', record)
    },
    clearRecording () {
      this.stream.getTracks().forEach((track) => track.stop())
      this.input.disconnect()
      this.processor.disconnect()
      this.context.close()
      this.duration2 = 0
      this.duration = 0
      this.isPause = false
      this.isRecording = false
    },
    _micCaptured (stream) {
      this.context = new (window.AudioContext || window.webkitAudioContext)()
      this.duration = this.duration2
      this.input = this.context.createMediaStreamSource(stream)
      this.processor = this.context.createScriptProcessor(this.bufferSize, 1, 1)
      this.stream = stream
      this.processor.onaudioprocess = (ev) => {
        const sample = ev.inputBuffer.getChannelData(0)
        let sum = 0.0
        this.encode(sample)
        for (let i = 0; i < sample.length; ++i) {
          sum += sample[i] * sample[i]
        }
        this.duration = parseFloat(this.duration2) + parseFloat(this.context.currentTime.toFixed(2))
        this.volume = Math.sqrt(sum / sample.length).toFixed(2)
      }
      this.input.connect(this.processor)
      this.processor.connect(this.context.destination)
    },
    _micError (error) {
      this.micFailed && this.micFailed(error)
    },
    encode (arrayBuffer) {
      const maxSamples = 1152
      const samples = this._convertBuffer(arrayBuffer)
      let remaining = samples.length
      for (let i = 0; remaining >= 0; i += maxSamples) {
        const left = samples.subarray(i, i + maxSamples)
        const buffer = this.encoder.encodeBuffer(left)
        this.dataBuffer.push(new Int8Array(buffer))
        remaining -= maxSamples
      }
    },
    finish () {
      this.dataBuffer.push(this.encoder.flush())
      const blob = new Blob(this.dataBuffer, { type: 'audio/mp3' })
      this.dataBuffer = []
      return {
        id: Date.now(),
        blob: blob,
        url: URL.createObjectURL(blob)
      }
    },
    _floatTo16BitPCM (input, output) {
      for (let i = 0; i < input.length; i++) {
        const s = Math.max(-1, Math.min(1, input[i]))
        output[i] = (s < 0 ? s * 0x8000 : s * 0x7FFF)
      }
    },
    _convertBuffer (arrayBuffer) {
      const data = new Float32Array(arrayBuffer)
      const out = new Int16Array(arrayBuffer.length)
      this._floatTo16BitPCM(data, out)
      return out
    }
  },
  computed: {
    recordedTime () {
      return this.convertTimeMMSS(this.duration)
    }
  },
  mounted () {
    this.setRecorder()
  },
  watch: {
    startRecording (val) {
      if (val) {
        this.toggleRecorder()
      }
    }
  }
}
</script>
<style lang="scss">
#btnCancel {
  background-color: #f9f9f9 !important;
}
#btnCancel:hover {
  background-color: #ff5252 !important;
  color: #f9f9f9 !important;
}
#btnIconCancel {
  font-size: 30px;
  color: #ff5252 !important;
}
#btnIconCancel:hover {
  color: #f9f9f9 !important;
}
#btnDone {
  background-color: #f9f9f9 !important;
}
#btnDone:hover {
  background-color: #25D366 !important;
  color: #f9f9f9 !important;
}
#btnIconDone {
  font-size: 30px;
  color: #25D366 !important;
}
#btnIconDone:hover {
  color: #f9f9f9 !important;
}
.icon-recording {
  color: #ff5252 !important;
  position: absolute;
  left: 62px;
  bottom: 15px;
  font-size: 12px !important;
}
@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.animate-flicker {
   -webkit-animation: flickerAnimation 1s infinite;
   -moz-animation: flickerAnimation 1s infinite;
   -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
}
  .ar {
    font-family: 'Roboto', sans-serif;
    // background-color: #FAFAFA;
    // box-shadow: 0 4px 18px 0 rgba(0,0,0,0.17);
    // box-sizing: content-box;
    position: absolute;
    width: 195px;
    border-radius: 30px;
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 55px;
      padding: 0;
    }
    &-recorder {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      width: 200px;
      height: 50px;
      left: 0;
      bottom: 0;
      &_duration2 {
        color: #AEAEAE;
        font-size: 24px;
        margin-bottom: 16px;
        margin-top: 10px;
        margin-left: 20px;
      }
      &__stop {
        width: 45px;
        height: 45px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    &__text {
      color: rgba(84,84,84,0.5);
      font-size: 16px;
    }
    &__blur {
      filter: blur(2px);
      opacity: 0.7;
    }
    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  }
  .ar-icon {
    position: absolute;

    &__sm {
      width: 45px;
      height: 45px;
      position: absolute;
      right: 5px;
      top: 0;
      border-style: solid;
      border-width: 1px;
      border-color: #25D366;
    }

    &__lg {
      position: absolute;
      left: 5px;
      width: 45px;
      height: 45px;
      border-style: solid;
      border-width: 1px;
      border-color: #ff5252;
    }
  }
</style>
