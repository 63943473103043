
<template>
  <div
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="toggleActive"
    :class="{ 'active-dropzone': active }"
    class="dropzone position-relative"
    style="cursor: pointer"
  >
    <div class="mb-1">
        <i class="display-4 text-muted ri-upload-cloud-2-fill"></i>
    </div>

    <h5>Arraste o arquivo ou Clique aqui para fazer o upload.</h5>
    <label :for="dropzoneFileName" class="bg-light stretched-link">Upload</label>
    <input type="file" :id="dropzoneFileName" :class="[dropzoneFileName, 'btn', 'btn-primary']" />
  </div>
</template>


<script>
import { ref } from "vue";
export default {
  name: "dropzone",
  props: {
    dropzoneFileName: {
      type: String,
      default: 'dropzoneFile'
    }
  },
  setup() {
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };
    return { active, toggleActive };
  },
};
</script>
